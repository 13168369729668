import { useEffect, useMemo } from 'react'

import {
  IEmailLogApiClient,
  IEmailLogSearchParams,
  IEmailLogSearchResponse,
  IEmailLogGetEmailDetailsRequest,
  IEmailLogGetEmailDetailsResponse
} from '@barracuda-internal/converged-email-log'

import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import useUserDataLib from 'global/lib/userData/useUserData'
import { getConvergedMessageLogs } from 'global/redux/features/convergedMessageLog/convergedMessageLogSlice'

import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'

export interface ConvergedMessageLogLogic {
  emailLogApiClient: IEmailLogApiClient
}

export default function useConvergedMessageLogLogic(): ConvergedMessageLogLogic[] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const { accessTokenId } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id
  }))

  // EmailLogApiClient instance for ConvergedMessageLog feature
  const emailLogApiClient = useMemo(() => {
    const search = (abortController: AbortController) => async (
      params: IEmailLogSearchParams
    ): Promise<IEmailLogSearchResponse> => {
      const logs = await dispatch(getConvergedMessageLogs({ params, abortController }))

      return logs.payload
    }

    // Michael, I mocked this for now.
    const getEmailDetails = (abortController: AbortController) => async (
      request: IEmailLogGetEmailDetailsRequest
    ): Promise<IEmailLogGetEmailDetailsResponse> => {
      return {
        /* placeholder response */
      } as IEmailLogGetEmailDetailsResponse
    }

    return {
      search,
      getEmailDetails
    }
  }, [dispatch])

  return useMemo(
    () => [
      {
        emailLogApiClient
      }
    ],
    [emailLogApiClient]
  )
}
