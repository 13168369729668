import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/index'
import { EmptyStateProps } from '@barracuda-internal/bds-core'

import validator from 'validator'

import { format24HoursTime, formatMonth, formatMonthWithDay } from 'global/lib/datetime'
import { BarChartData, BarChartOptions, ChartDimension } from 'global/components/lib/chartsV2/BarChart'
import { LineChartData, LineChartOptions } from 'global/components/lib/chartsV2/LineChart'

export interface ChartConfig {
  chartData: any[]
  chartDimension: ChartDimension
  chartFieldNames: string[]
  chartPeriod: string
  chartPeriodOptions: ChartPeriod[]
  chartType: ChartType
  emptyState: EmptyStateProps
  hasEntries: boolean
  isMultiSeriesData: boolean
  isSingleColumnGrouped: boolean
  showBarChart: boolean
  showStackedBarChart: boolean
  setShowBarChart: () => void
  setShowStackedBarChart: () => void
  updateChartConfig: (configKey: string, value: string) => void
}

export interface MultiSeriesDataChartConfig {
  data: BarChartData
  options?: { horizontalGrouped: BarChartOptions; horizontalStacked: BarChartOptions }
}

export interface SingleSeriesDataChartConfig {
  data: { barChartData: BarChartData; lineChartData: LineChartData }
  options?: {
    barChartOptions: { horizontal?: BarChartOptions; vertical: BarChartOptions }
    lineChartOptions: LineChartOptions
  }
}

export interface EmptyState {
  icon: string
  text: string
}

export enum ChartPeriod {
  daily = 'DAILY',
  hourly = 'HOURLY',
  monthly = 'MONTHLY',
  weekly = 'WEEKLY'
}

export enum ChartType {
  verticalBar = 'VERTICAL_BAR',
  horizontalBar = 'HORIZONTAL_BAR',
  horizontalStackedBar = 'HORIZONTAL_STACKED_BAR',
  horizontalGroupedBar = 'HORIZONTAL_GROUPED_BAR',
  line = 'LINE',
  table = 'TABLE'
}

export const chartDimension = {
  height: '400px',
  width: '100%'
}

export const tooltip = {
  borderColor: colors.scaleGray100,
  borderWidth: 1,
  titleColor: colors.black,
  titleFont: {
    size: 14
  },
  backgroundColor: colors.white,
  bodyColor: colors.black,
  bodySpacing: 6,
  padding: bdsTheme.default.spacing(1.5),
  bodyFont: {
    size: 14
  },
  usePointStyle: true
}

export const legend = {
  display: false
}

export const getChartBackgroundColor = (index: number): string => {
  // TODO: update hex code names chartBarracudalightPurple and chartBarracudaDarkOranage, and replace hardcoded color #DAE0B8 once BDS release the changes of them.
  const backgroundColors = [
    colors.chartBarracudaLightBlue,
    colors.chartBarracudaGreen,
    colors.chartBarracudaOrange,
    colors.chartBarracudalightPurple,
    colors.chartBarracudaDarkPurple,
    colors.chartBarracudaTan,
    colors.chartBarracudaMagenta,
    '#DAE0B8',
    colors.chartBarracudaPink,
    colors.chartBarracudaDarkOranage,
    colors.chartBarracudaDarkBrown
  ]
  return backgroundColors[index]
}

/**
 * Splits the chart label string into substrings if the length of the label is greater than 60 characters: if
 * it's a valid email, split by '@' symbol, otherwise split the string into chunks of up to 60 characters,
 * with each chunk ending either with whitespace or at the end of the string.
 */
export const splitLabel = (label: string): any => {
  if (label.length > 60) {
    return validator.isEmail(label) ? label.split(/(?=@)/g) : label.match(/.{1,60}(?:\s|$)/g)
  }

  return label
}

export const formatLabels = (chartPeriod: string, labels: string[], isGrouping: boolean): string[] => {
  if (isGrouping) {
    return labels.map(label => splitLabel(label))
  }

  switch (chartPeriod) {
    case ChartPeriod.hourly:
      return labels.map(label => format24HoursTime(label))
    case ChartPeriod.weekly:
    case ChartPeriod.daily:
      return labels.map(label => formatMonthWithDay(label))
    default:
      return labels.map(label => formatMonth(label))
  }
}
