import React, { useMemo, useCallback, useReducer } from 'react'

import * as dmarcLib from 'global/lib/domain/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { luxonDate } from 'global/lib/datetime'
import attackTypeValidator, { ATTACK_TYPES, ETS_ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import { useFormatMessage } from 'global/lib/localization'

import AreaChart from 'ets/components/lib/chartPanel/AreaChart'
import BarChart from 'ets/components/lib/chartPanel/BarChart'
import { ChartPanelProps } from 'ets/components/lib/chartPanel/ChartPanel'
import { useAppSelector } from 'ets/redux/toolkit/hooks'
import styles from 'ets/components/pages/dashboard/overview/dashboardOverviewStyles'

export interface DmarcConfig {
  id: string
  count: number
  color: keyof ReturnType<typeof styles>
}

export interface DashboardOverviewLogic {
  totalThreatsFoundPanelConfig: ChartPanelProps
  employeesWithThreatsPanelConfig: ChartPanelProps
  threatTypesFoundPanelConfig: ChartPanelProps
  domainDmarcStatusConfig: ChartPanelProps
  hoveredThreatType: string
  dmarcConfig: {
    maxWidth: number
    items: DmarcConfig[]
  }
}

export interface DashboardOverviewLogicProps {
  scrollToSection: (sectionIndex: number) => void
}

export type State = {
  isHoveredThreatTypeInitialized: boolean
  hoveredThreatType: string
}

export type ThreatTypesChartData = {
  taxonomy: string
  spFraudCount: number
  type: string
  description: string
}

const DMARC_COUNT_FONT_WIDTH = 25
const BASE_ATTACK_TYPES_KEY = 'app.attack_types'

export default function useDashboardOverviewLogic({
  scrollToSection
}: DashboardOverviewLogicProps): [DashboardOverviewLogic] {
  const formatMessage = useFormatMessage(BASE_ATTACK_TYPES_KEY)

  const {
    isInitialScanStatsLoaded,
    scanStats,
    domainsData,
    isAttacksByThreatsReportLoaded,
    attacksByThreatsReport,
    isAttacksPerMonthReportLoaded,
    attacksPerMonthReport,
    isEmployeesWithThreatsPerMonthReportLoaded,
    employeesWithThreatsPerMonthReport,
    isDomainsReportLoaded
  } = useAppSelector(_stores => ({
    isInitialScanStatsLoaded: _stores.scan.isInitialScanStatsLoaded,
    scanStats: _stores.scan.stats,
    domainsData: _stores.reports.domains.data,
    isAttacksByThreatsReportLoaded: !!_stores.reports.chartReport.attacksByThreats.accessTokenId,
    attacksByThreatsReport: _stores.reports.chartReport.attacksByThreats.report,
    isAttacksPerMonthReportLoaded: !!_stores.reports.chartReport.attacksPerMonth.accessTokenId,
    attacksPerMonthReport: _stores.reports.chartReport.attacksPerMonth.report,
    isEmployeesWithThreatsPerMonthReportLoaded: _stores.reports.chartReport.employeesWithThreatsPerMonth.accessTokenId,
    employeesWithThreatsPerMonthReport: _stores.reports.chartReport.employeesWithThreatsPerMonth.report,
    isDomainsReportLoaded: !!_stores.reports?.domains?.data?.accessTokenId || false
  }))
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    isHoveredThreatTypeInitialized: false,
    hoveredThreatType: ETS_ATTACK_TYPES.SCAMMING
  })

  const LOCALIZED_ATTACK_TYPES = useMemo(() => {
    return [
      ETS_ATTACK_TYPES.CONVERSATION_HIJACKING,
      ETS_ATTACK_TYPES.EXTORTION,
      ETS_ATTACK_TYPES.IMPERSONATION,
      ETS_ATTACK_TYPES.PHISHING,
      ETS_ATTACK_TYPES.SCAMMING
    ].reduce((all: { [key: string]: string }, threatType: string) => {
      return {
        ...all,
        [threatType]: formatMessage(`${threatType}.text`)
      }
    }, {})
  }, [formatMessage])

  const onDetailsClick = useCallback(
    (sectionIndex: number, sectionName: any) => {
      return (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.overviewDetails(sectionName), { url: window.location.href })
        scrollToSection(sectionIndex)
      }
    },
    [scrollToSection]
  )

  const updateHoveredThreatType = useCallback(
    (item: ThreatTypesChartData) => {
      if (item && item.taxonomy) {
        const threatType = attackTypeValidator(item.taxonomy)

        if (threatType !== state.hoveredThreatType) {
          setState({ hoveredThreatType: threatType, isHoveredThreatTypeInitialized: true })
        }
      }
    },
    [state.hoveredThreatType]
  )

  const onCategoryAxisLabelHover = useCallback(
    (localizedValue: string) => {
      const newThreatType = Object.keys(LOCALIZED_ATTACK_TYPES).find(
        (threatType: string) => LOCALIZED_ATTACK_TYPES[threatType] === localizedValue
      )

      if (newThreatType && state.hoveredThreatType !== newThreatType) {
        setState({ hoveredThreatType: newThreatType })
      }
    },
    [LOCALIZED_ATTACK_TYPES, state.hoveredThreatType]
  )

  const onthreatTypesFoundSeriesHover = useCallback(
    (e: { value: number }) => {
      const threatItem = attacksByThreatsReport.data.find(
        (item: { spFraudCount: number }) => item.spFraudCount === e.value
      )

      updateHoveredThreatType(threatItem)
    },
    [attacksByThreatsReport, updateHoveredThreatType]
  )

  const totalThreatsFoundPanelConfig: ChartPanelProps = useMemo(() => {
    const inProgress = !isAttacksPerMonthReportLoaded || !isInitialScanStatsLoaded
    const total: number = (attacksPerMonthReport.data || []).reduce((all: number, report: { spFraudCount: number }) => {
      return all + report.spFraudCount
    }, 0)
    const chartData = [...Array(12).keys()].map((i: number) => {
      const date = luxonDate(scanStats.finishedOn)
        .startOf('month')
        .minus({ months: 11 - i })

      const reportInRange = (attacksPerMonthReport.data || []).find(
        (report: { date: string }) => report.date === date.toFormat('yyyy-MM-dd')
      ) || { spFraudCount: 0 }

      return {
        spFraudCount: reportInRange.spFraudCount,
        date: date.toJSDate()
      }
    })

    return {
      section: 'total_threats_found',
      total: inProgress ? 123456 : total,
      chart: (
        <AreaChart
          chartData={inProgress ? undefined : { data: chartData, field: 'spFraudCount', categoryField: 'date' }}
        />
      ),
      inProgress,
      onDetailsClick: onDetailsClick(2, 'Threats')
    }
  }, [
    isInitialScanStatsLoaded,
    onDetailsClick,
    attacksPerMonthReport,
    isAttacksPerMonthReportLoaded,
    scanStats.finishedOn
  ])

  const employeesWithThreatsPanelConfig: ChartPanelProps = useMemo(() => {
    const inProgress = !isEmployeesWithThreatsPerMonthReportLoaded || !isInitialScanStatsLoaded
    const chartData = [...Array(12).keys()].map((i: number) => {
      const date = luxonDate(scanStats.finishedOn)
        .startOf('month')
        .minus({ months: 11 - i })

      const reportInRange = (employeesWithThreatsPerMonthReport.data || []).reduce(
        (all: number, report: { date: string; identityCount: number }) =>
          all + Number(report.date === date.toFormat('yyyy-MM-dd') ? report.identityCount : 0),
        0
      )

      return {
        identityCount: reportInRange,
        date: date.toJSDate()
      }
    })

    return {
      section: 'employees_with_threats',
      total: inProgress ? 415 : employeesWithThreatsPerMonthReport.identityTotal || 0,
      chart: (
        <AreaChart
          chartData={inProgress ? undefined : { data: chartData, field: 'identityCount', categoryField: 'date' }}
        />
      ),
      inProgress,
      onDetailsClick: onDetailsClick(1, 'Employees Threats')
    }
  }, [
    isInitialScanStatsLoaded,
    onDetailsClick,
    employeesWithThreatsPerMonthReport,
    isEmployeesWithThreatsPerMonthReportLoaded,
    scanStats.finishedOn
  ])

  const threatTypesFoundPanelConfig: ChartPanelProps = useMemo(() => {
    const inProgress = !isAttacksByThreatsReportLoaded || !isInitialScanStatsLoaded

    const chartData = [
      ETS_ATTACK_TYPES.CONVERSATION_HIJACKING,
      ETS_ATTACK_TYPES.EXTORTION,
      ETS_ATTACK_TYPES.IMPERSONATION,
      ETS_ATTACK_TYPES.PHISHING,
      ETS_ATTACK_TYPES.SCAMMING
    ]
      .reduce((all: ThreatTypesChartData[], threatType: string) => {
        const threatItem = (attacksByThreatsReport.data || []).find((report: { taxonomy: string }) => {
          // attackTypeValidator is a bit broken, since it uses `find` and returns the first match which is always
          // ATTACK_TYPES.SCAMMING for the attack type 'spam'. So we need to check for scamming_graymail separately.
          // This is a little hacky since we are going to update the attack types to use the same label in about a month.
          // TODO: Remove this when we update the backend to use the same label for scamming and scamming_graymail.
          if (threatType === ETS_ATTACK_TYPES.SCAMMING) {
            return attackTypeValidator(report.taxonomy) === ATTACK_TYPES.SCAMMING
          }
          return attackTypeValidator(report.taxonomy) === threatType
        }) || { spFraudCount: 0, taxonomy: '' }

        return [
          ...all,
          {
            taxonomy: threatItem.taxonomy,
            spFraudCount: threatItem.spFraudCount,
            type: LOCALIZED_ATTACK_TYPES[threatType],
            description: formatMessage(`${threatType}.tooltip`)
          }
        ]
      }, [])
      .sort((a: ThreatTypesChartData, b: ThreatTypesChartData) => b.spFraudCount - a.spFraudCount)

    if (!state.isHoveredThreatTypeInitialized) {
      updateHoveredThreatType(chartData[0])
    }

    return {
      section: 'threat_types_found',
      chart: (
        <BarChart
          chartData={inProgress ? undefined : { data: chartData, field: 'spFraudCount', categoryField: 'type' }}
          onSeriesHover={onthreatTypesFoundSeriesHover}
          onCategoryAxisLabelHover={onCategoryAxisLabelHover}
        />
      ),
      inProgress,
      showTotal: false,
      onDetailsClick: onDetailsClick(2, 'Threat Types')
    }
  }, [
    formatMessage,
    state.isHoveredThreatTypeInitialized,
    isInitialScanStatsLoaded,
    onDetailsClick,
    attacksByThreatsReport,
    isAttacksByThreatsReportLoaded,
    onthreatTypesFoundSeriesHover,
    updateHoveredThreatType,
    onCategoryAxisLabelHover,
    LOCALIZED_ATTACK_TYPES
  ])

  const domainDmarcStatusConfig: ChartPanelProps = useMemo(() => {
    return {
      section: 'domain_dmarc_status',
      intlTagHandlers: { b: (text: string) => <b key={text}>{text}</b> },
      inProgress: !isDomainsReportLoaded || !isInitialScanStatsLoaded,
      showTotal: false,
      onDetailsClick: onDetailsClick(3, 'DMARC')
    }
  }, [isDomainsReportLoaded, isInitialScanStatsLoaded, onDetailsClick])

  const dmarcConfig: {
    maxWidth: number
    items: DmarcConfig[]
  } = useMemo(() => {
    const counts = dmarcLib.dmarcCounts(domainsData?.report.data || [], true)

    return {
      maxWidth: Math.max(...Object.values(counts)).toString().length * DMARC_COUNT_FONT_WIDTH + 12,
      items: [
        {
          id: 'not_configured',
          count: counts[dmarcLib.DMARC_STATES.UNPROTECTED],
          color: 'dmarcRed'
        },
        {
          id: 'reporting_mode',
          count: counts[dmarcLib.DMARC_STATES.REPORTING],
          color: 'dmarcYellow'
        },
        {
          id: 'enforcement_mode',
          count: counts[dmarcLib.DMARC_STATES.PROTECTED],
          color: 'dmarcGreen'
        }
      ]
    }
  }, [domainsData])

  return useMemo(
    () => [
      {
        totalThreatsFoundPanelConfig,
        employeesWithThreatsPanelConfig,
        threatTypesFoundPanelConfig,
        domainDmarcStatusConfig,
        hoveredThreatType: state.hoveredThreatType,
        dmarcConfig
      }
    ],
    [
      totalThreatsFoundPanelConfig,
      employeesWithThreatsPanelConfig,
      threatTypesFoundPanelConfig,
      domainDmarcStatusConfig,
      state.hoveredThreatType,
      dmarcConfig
    ]
  )
}
