import React, { useMemo } from 'react'

import GlobalConvergedMessageLog from 'global/components/features/convergedMessageLog/ConvergedMessageLog'

import Layout from 'sen/components/lib/layout/Layout'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

const ConvergedMessageLog: React.FC = () => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { userBccId, pageTitle, pageSubtitle } = convergedMessageLogsLogic

  return useMemo(
    () => (
      <Layout pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
        <GlobalConvergedMessageLog bccId={userBccId} />
      </Layout>
    ),
    [pageTitle, pageSubtitle, userBccId]
  )
}

export default ConvergedMessageLog
