import { createAsyncThunk } from '@reduxjs/toolkit'

import { IEmailLogSearchParams } from '@barracuda-internal/converged-email-log'

import restClient from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'

type GetConvergedMessageLogsParams = {
  params: IEmailLogSearchParams
  abortController: AbortController
}

export const getConvergedMessageLogs = createAsyncThunk(
  'CONVERGED_MESSAGE_LOG/getConvergedMessageLogs',
  async function doGetConvergedMessageLog(
    { params, abortController }: GetConvergedMessageLogsParams,
    { rejectWithValue }
  ) {
    try {
      const resp = await restClient(globalApiRoutes.GET_CONVERGED_MESSAGE_LOG, {
        data: params,
        signal: abortController.signal
      })

      return resp.data
    } catch (e) {
      // TODO: set a global error state (reportSlice)?
      return rejectWithValue('error_getting_converged_message_logs')
    }
  }
)
