import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import { EmailLogProvider, EmailLog } from '@barracuda-internal/converged-email-log'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

interface ConvergedMessageLogProps {
  bccId: string
}

const ConvergedMessageLog: React.FC<ConvergedMessageLogProps> = ({ bccId }) => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { emailLogApiClient } = convergedMessageLogsLogic

  return useMemo(
    () => (
      <EmailLogProvider bccAccountId={bccId} emailLogApiClient={emailLogApiClient}>
        <Grid container data-testid="container">
          <EmailLog />
        </Grid>
      </EmailLogProvider>
    ),
    [bccId, emailLogApiClient]
  )
}

export default ConvergedMessageLog
