import { useEffect, useMemo } from 'react'

import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'

import { useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

const BASE_I18N_KEY = 'sen.app.converged_message_log'

export interface ConvergedMessageLogLogic {
  pageTitle: string
  pageSubtitle: string
  userBccId: string
}

export default function useConvergedMessageLogLogic(): ConvergedMessageLogLogic[] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [userDataLib] = useUserDataLib()
  const { accessTokenId, userBccId, splitStore } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    userBccId: _stores.user.data.bccUserId,
    splitStore: _stores.splitio
  }))

  // Check if ConvergedMessageLog feature is on
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isConvergedMessageLogFeatureOn = useMemo(
    () => isMyFeatureOn(splitStore, FEATURES.EGD_IP_IR_CONVERGED_EMAIL_LOG_ROLLOUT, accountId),
    [accountId, splitStore]
  )

  // If convergedMessageLog feature is not on, redirect to dashboard
  useEffect(() => {
    if (!isConvergedMessageLogFeatureOn) {
      routesConfig.DASHBOARD.goto({ reportId: accessTokenId })
    }
  }, [accessTokenId, isConvergedMessageLogFeatureOn])

  return useMemo(
    () => [
      {
        pageTitle: formatMessage('page_title'),
        pageSubtitle: formatMessage('page_subtitle'),
        userBccId
      }
    ],
    [formatMessage, userBccId]
  )
}
