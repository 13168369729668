import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { union } from 'lodash'

import { Duration } from 'luxon'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { ScansReport } from 'global/types/api/scan'
import { HardResetReport } from 'global/redux/features/reports/reducerHandlers'
import { ProductAssigment, ProductLicense } from 'global/types/api/product'
import { humanizeDuration } from 'global/lib/datetime'
import insertToArray from 'global/lib/insertToArray'

import routesConfig from 'admin/lib/routes/routesConfig'
import {
  deactivate,
  deactivateAndReset,
  getProductAssignments,
  getProductLicenses,
  getRemediationStats,
  getUsers,
  getScans,
  impersonate,
  listAccessTokens,
  scanTotals,
  setSentinelSerialNumber,
  sendThreatsFolderView
} from 'admin/redux/features/admin/adminApiThunks'
import {
  update as updateScansTable,
  INITIAL_STATE as scansTableInitialState
} from 'admin/redux/features/dataTables/etsScans/etsScansSlice'

import { RootState } from 'admin/redux/toolkit/store'

import { ListAccessTokenResult, ThreatsFolderViewResults } from 'admin/redux/types/scans'
import { RemediationStats, TransformedRemediationStats } from 'admin/redux/types/remediation'
import { AdminUser } from 'admin/redux/types/user'

export interface AdminState {
  accessTokens: ListAccessTokenResult
  deactivateApiStatus: ApiStatus
  deactivateAndResetApiStatus: ApiStatus
  impersonateApiStatus: ApiStatus
  getProductAssignmentsApiStatus: ApiStatus
  getProductLicensesApiStatus: ApiStatus
  getRemediationStatsApiStatus: ApiStatus
  getScansApiStatus: ApiStatus
  getUsersApiStatus: ApiStatus
  listAccessTokensApiStatus: ApiStatus
  loadedAccessTokensOffsets: number[]
  loadedScansOffsets: number[]
  productAssigment: ProductAssigment
  productLicense: ProductLicense
  remediationStats: RemediationStats
  scans: ScansReport
  scansCount: number | undefined
  scanTotalsApiStatus: ApiStatus
  setSentinelSerialNumberApiStatus: ApiStatus
  totals: Totals
  transformedRemediationStats: TransformedRemediationStats[]
  users: AdminUser[]
  sendThreatsFolderViewApiStatus: ApiStatus
  threatsFolderView: ThreatsFolderViewResults
}

export interface TransformRemediationStatsPayload {
  remediationStats: RemediationStats
  count: number
}

export interface Totals {
  accountsCount: number
  identityCount: number
  mailboxCount: number
  scanCount: number
  spAttacksCount: number
}

// initialState
export const INITIAL_STATE: AdminState = {
  accessTokens: {} as ListAccessTokenResult,
  deactivateApiStatus: inIdle,
  deactivateAndResetApiStatus: inIdle,
  getProductAssignmentsApiStatus: inIdle,
  getProductLicensesApiStatus: inIdle,
  getRemediationStatsApiStatus: inIdle,
  getScansApiStatus: inIdle,
  getUsersApiStatus: inIdle,
  impersonateApiStatus: inIdle,
  listAccessTokensApiStatus: inIdle,
  loadedScansOffsets: [],
  loadedAccessTokensOffsets: [],
  productAssigment: {} as ProductAssigment,
  productLicense: {} as ProductLicense,
  remediationStats: {} as RemediationStats,
  setSentinelSerialNumberApiStatus: inIdle,
  scans: {} as ScansReport,
  scansCount: undefined,
  scanTotalsApiStatus: inIdle,
  totals: {} as Totals,
  transformedRemediationStats: [],
  users: [],
  sendThreatsFolderViewApiStatus: inIdle,
  threatsFolderView: {} as ThreatsFolderViewResults
}

const resetScans = createAsyncThunk<number, HardResetReport>('ADMIN/resetScans', async (_, { getState }) => {
  const store = getState() as RootState
  const activePath = store.app.activePath.id
  const offsets = {
    [routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER.id]: store.dataTables.etsScans.skip,
    [routesConfig.DASHBOARD_IMPERSONATION_PROTECTION.id]: store.dataTables.senScans.skip,
    [routesConfig.DASHBOARD_FORENSICS_ACCOUNTS.id]: store.dataTables.firAccessTokens.skip
  }

  return activePath ? offsets[activePath] : 0
})

/* eslint-disable no-param-reassign */
export const adminSlice = createSlice({
  name: 'ADMIN',
  initialState: INITIAL_STATE,
  reducers: {
    updateProductLicenseNumber: (state, action: PayloadAction<string>) => {
      state.productLicense.serialNumber = action.payload
    },
    transformRemediationStats: (state, action: PayloadAction<TransformRemediationStatsPayload>) => {
      state.transformedRemediationStats = transformRemediationStatsToTable(
        action.payload.remediationStats,
        action.payload.count
      )
    },
    resetAccessTokens: (state, action: PayloadAction<HardResetReport>) => {
      state.accessTokens = resetReport(state.accessTokens, action.payload) || INITIAL_STATE.accessTokens
      state.loadedAccessTokensOffsets = INITIAL_STATE.loadedAccessTokensOffsets
      state.listAccessTokensApiStatus = inIdle
    },
    resetGetProductAssiments: state => {
      state.getProductAssignmentsApiStatus = inIdle
    },
    resetGetProductLicenses: state => {
      state.getProductLicensesApiStatus = inIdle
    },
    resetGetUsers: state => {
      state.getUsersApiStatus = inIdle
      state.users = INITIAL_STATE.users
    },
    // TODO: REMOVE - Not used
    resetSentinelSerialNumber: state => {
      state.setSentinelSerialNumberApiStatus = inIdle
    },
    resetSendThreatsFolderView: state => {
      state.sendThreatsFolderViewApiStatus = inIdle
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(resetScans.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.scans = resetReport(state.scans, doHardReset) || INITIAL_STATE.scans
        state.scanTotalsApiStatus = doHardReset ? INITIAL_STATE.scanTotalsApiStatus : state.scanTotalsApiStatus
        state.loadedScansOffsets = doHardReset ? INITIAL_STATE.loadedScansOffsets : [action.payload]
      })

      .addCase(scanTotals.pending, state => {
        state.scanTotalsApiStatus = inProgress
      })
      .addCase(scanTotals.fulfilled, (state, action) => {
        state.scanTotalsApiStatus = successResponse
        state.totals = action.payload.scanTotals
      })
      .addCase(scanTotals.rejected, (state, action) => {
        state.scanTotalsApiStatus = failedResponse(action.payload as string)
      })
      .addCase(updateScansTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedScansOffsets = union(state.loadedScansOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getScans.pending, state => {
        state.getScansApiStatus = inProgress
        if (!state.loadedScansOffsets.length) {
          state.loadedScansOffsets = [scansTableInitialState.skip]
        }
      })
      .addCase(getScans.fulfilled, (state, action) => {
        state.getScansApiStatus = successResponse
        state.scans = {
          ...state?.scans,
          ...(action.payload as any)?.report?.scans,
          data: insertToArray(
            state?.scans?.data || [],
            (action.payload as any).report.scans.data,
            (action.payload as any).offset || 0
          ),
          accessTokens: { ...state?.scans?.accessTokens, ...(action.payload as any).report.accessTokens },
          userFlags: { ...state?.scans?.userFlags, ...(action.payload as any).report.userFlags }
        }
      })
      .addCase(getScans.rejected, (state, action) => {
        state.getScansApiStatus = failedResponse(action.payload as string)
      })
      .addCase(listAccessTokens.pending, state => {
        state.listAccessTokensApiStatus = inProgress
        if (!state.loadedAccessTokensOffsets.length) {
          state.loadedAccessTokensOffsets = [scansTableInitialState.skip]
        }
      })
      .addCase(listAccessTokens.fulfilled, (state, action) => {
        state.listAccessTokensApiStatus = successResponse
        state.accessTokens = {
          ...state?.accessTokens,
          ...(action.payload as any)?.report?.accessTokens,
          data: insertToArray(
            state?.accessTokens?.data || [],
            (action.payload as any).report.accessTokens.content,
            (action.payload as any).offset || 0
          )
        }
      })
      .addCase(listAccessTokens.rejected, (state, action) => {
        state.listAccessTokensApiStatus = failedResponse(action.payload as string)
      })
      .addCase(impersonate.pending, state => {
        state.impersonateApiStatus = inProgress
      })
      .addCase(impersonate.fulfilled, state => {
        state.impersonateApiStatus = successResponse
      })
      .addCase(impersonate.rejected, (state, action) => {
        state.impersonateApiStatus = failedResponse(action.payload as string)
      })
      .addCase(deactivate.pending, state => {
        state.deactivateApiStatus = inProgress
      })
      .addCase(deactivate.fulfilled, state => {
        state.deactivateApiStatus = successResponse
      })
      .addCase(deactivate.rejected, (state, action) => {
        state.deactivateApiStatus = failedResponse(action.payload as string)
      })
      .addCase(deactivateAndReset.pending, state => {
        state.deactivateAndResetApiStatus = inProgress
      })
      .addCase(deactivateAndReset.fulfilled, state => {
        state.deactivateAndResetApiStatus = successResponse
      })
      .addCase(deactivateAndReset.rejected, (state, action) => {
        state.deactivateAndResetApiStatus = failedResponse(action.payload as string)
      })
      .addCase(setSentinelSerialNumber.pending, state => {
        state.setSentinelSerialNumberApiStatus = inProgress
      })
      .addCase(setSentinelSerialNumber.fulfilled, state => {
        state.setSentinelSerialNumberApiStatus = successResponse
      })
      .addCase(setSentinelSerialNumber.rejected, (state, action) => {
        state.setSentinelSerialNumberApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getProductAssignments.pending, state => {
        state.getProductAssignmentsApiStatus = inProgress
      })
      .addCase(getProductAssignments.fulfilled, (state, action) => {
        state.productAssigment = action.payload
        state.getProductAssignmentsApiStatus = successResponse
      })
      .addCase(getProductAssignments.rejected, (state, action) => {
        state.getProductAssignmentsApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getProductLicenses.pending, state => {
        state.getProductLicensesApiStatus = inProgress
      })
      .addCase(getProductLicenses.fulfilled, (state, action) => {
        state.productLicense = action.payload
        state.getProductLicensesApiStatus = successResponse
      })
      .addCase(getProductLicenses.rejected, (state, action) => {
        state.getProductLicensesApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getRemediationStats.pending, state => {
        state.getRemediationStatsApiStatus = inProgress
      })
      .addCase(getRemediationStats.fulfilled, (state, action) => {
        state.remediationStats = action.payload
        state.getRemediationStatsApiStatus = successResponse
      })
      .addCase(getRemediationStats.rejected, (state, action) => {
        state.getRemediationStatsApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getUsers.pending, state => {
        state.getUsersApiStatus = inProgress
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
        state.getUsersApiStatus = successResponse
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.getUsersApiStatus = failedResponse(action.payload as string)
      })
      .addCase(sendThreatsFolderView.pending, state => {
        state.sendThreatsFolderViewApiStatus = inProgress
      })
      .addCase(sendThreatsFolderView.fulfilled, (state, action) => {
        state.threatsFolderView = action.payload
        state.sendThreatsFolderViewApiStatus = successResponse
      })
      .addCase(sendThreatsFolderView.rejected, (state, action) => {
        state.sendThreatsFolderViewApiStatus = failedResponse(action.payload as string)
      })
  }
})

/* eslint-enable no-param-reassign */
export const {
  updateProductLicenseNumber,
  transformRemediationStats,
  resetAccessTokens,
  resetGetProductAssiments,
  resetGetProductLicenses,
  resetGetUsers,
  resetSentinelSerialNumber,
  resetSendThreatsFolderView,
  reset
} = adminSlice.actions

export {
  deactivate,
  deactivateAndReset,
  getProductAssignments,
  getProductLicenses,
  getRemediationStats,
  getUsers,
  getScans,
  impersonate,
  listAccessTokens,
  resetScans,
  scanTotals,
  setSentinelSerialNumber,
  sendThreatsFolderView
}

export default adminSlice.reducer

function resetReport(report: any, doHardReset: HardResetReport): any | undefined {
  if (report) {
    if (doHardReset) {
      return undefined
    }
    return {
      ...report,
      data: []
    }
  }

  return undefined
}

function transformRemediationStatsToTable(
  stats: RemediationStats,
  identityCount: number
): TransformedRemediationStats[] {
  return [
    { name: 'Total Incident Count', last30Days: stats.incidentsCountLast30Days, lifetime: stats.incidentsCount },
    { name: 'Total Emails Deleted', last30Days: stats.deletedEmailCountLast30Days, lifetime: stats.deletedEmailCount },

    {
      name: 'Average Percent of Mailboxes Impacted',
      last30Days:
        stats.mailboxesPerIncidentAvgLast30Days && identityCount
          ? `${((stats.mailboxesPerIncidentAvgLast30Days / identityCount) * 100).toFixed(2)}%`
          : 0,
      lifetime:
        stats.mailboxesPerIncidentAvg && identityCount
          ? `${((stats.mailboxesPerIncidentAvg / identityCount) * 100).toFixed(2)}%`
          : 0
    },
    {
      name: 'Average Mailbox Per Incident',
      last30Days: stats.mailboxesPerIncidentAvgLast30Days.toFixed(2),
      lifetime: stats.mailboxesPerIncidentAvg.toFixed(2)
    },
    {
      name: 'Average Deleted Email Age',
      last30Days: stats.deletedEmailAgeAvgMinutesLast30Days
        ? humanizeDuration(stats.deletedEmailAgeAvgMinutesLast30Days)
        : 0,
      lifetime: stats.deletedEmailAgeAvgMinutes
        ? humanizeDuration(Duration.fromObject({ minutes: stats.deletedEmailAgeAvgMinutes }), {
            units: ['h'],
            round: true
          })
        : 0
    }
  ]
}
